<template>
  <section class="section">
    <div class="container">
      <StockviewTabs />
      <ActionBar :count="actionableVehicles" v-if="summary" />
      <slot v-if="seller && summary" />
      <div v-else>
        <TableEmpty
          v-if="!seller"
          :text="
            !isAdmin
              ? 'This branch is currently not attached to a seller'
              : 'Select a dealership to populate'
          "
        />
        <button v-else class="button is-large is-white is-loading" />
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Stockview',
  components: {
    ActionBar: () => import('../components/StockviewActionBar'),
    StockviewTabs: () => import('../components/StockviewTabs'),
    TableEmpty: () => import('core/components/TableEmpty')
  },
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    ...mapGetters('stockview/dashboard', [
      'summary',
      'actionableVehicles',
      'seller'
    ])
  }
}
</script>

<style lang="sass">
.card-header
  background: $white
</style>
